import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Image,
} from "@chakra-ui/react";
import { Fade } from "react-reveal";

export default function Experience({ color }) {

  return (
    <>
      <Container maxW={"3xl"} id="experience">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" px={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                02
              </Text>
              <Text fontWeight={800}>Education</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Stack px={4} spacing={4}>
            <Fade bottom>
              <Card size="sm">
                <CardHeader>
                  <Flex justifyContent="space-between">
                    <HStack>
                      <Image m={2} h={50} src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2F12vite.com%2Fwp-content%2Fuploads%2F2018%2F08%2FUniversity_of_Tirana_logo.svg_-1536x1536.png&f=1&nofb=1&ipt=edd9cd28c420d4b76b44cf3878c3a72708da4c1abe7369f47b7f3db6509f020e&ipo=images'></Image>
                      <Box px={2} align="left">
                        <Text fontWeight={600}>University of Tirana</Text>
                        <Text>Informatics</Text>
                      </Box>
                    </HStack>
                    <Text px={2} fontWeight={400}>
                      2022-2025
                    </Text>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Text px={2}>
                    The Bachelor program in Informatics aims to equip students with foundational knowledge in 
                    informatics and related fields. Graduates will be prepared for advanced studies or technical roles
                    in IT management and production across public and private sectors.
                  </Text>
                </CardBody>
              </Card>
              <Card size="sm">
                <CardHeader>
                  <Flex justifyContent="space-between">
                    <HStack>
                      <Image m={2} h={50} src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcourse_report_production.s3.amazonaws.com%2Frich%2Frich_files%2Frich_files%2F5169%2Fs300%2Finstagram-feed180.jpg&f=1&nofb=1&ipt=04a6f4907ddcfd4c174aac3ad28911a4e87f61cd5dde0bc87007364f1462e77e&ipo=images'></Image>
                      <Box px={2} align="left">
                        <Text fontWeight={600}>Holberton School</Text>
                        <Text>Foundation of Computer Science</Text>
                      </Box>
                    </HStack>
                    <Text px={2} fontWeight={400}>
                      Feb-Nov 2024
                    </Text>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Text px={2}>
                    Holberton School is a Computer Science institution founded in Silicon Valley to address the educational gap for aspiring
                    software engineers. Its intensive 9-month program covers a wide range of foundational topics, including low-level programming 
                    languages like C, object-oriented programming, and web development, providing students with the skills needed to thrive in the tech industry.
                  </Text>
                </CardBody>
              </Card>
            </Fade>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
