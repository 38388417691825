import {
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useColorModeValue,
  Stack,
  IconButton,
  useMediaQuery,
  useDisclosure,
  HStack,
  VStack,
  Link,
  Image
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useState } from "react";
import logo from '../logo.png'
const TbIcons = require("react-icons/tb");

export default function Nav({ color }) {
  const colors = {
  "blue": "#3182CE", 
  "cyan": "#00B5D8", 
  "gray": "#718096", 
  "green": "#38A169", 
  "orange": "#DD6B20", 
  "pink": "#D53F8C", 
  "purple": "#805AD5", 
  "red": "#E53E3E", 
  "teal": "#319795", 
  "yellow": "#D69E2E"};
  const [scroll, setScroll] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const scrollToSection = (e) => {
    const section = document.querySelector(`#${e.target.name}`);
    section.scrollIntoView({ behavior: "smooth" });
    onClose();
  };

  const changeScroll = () =>
    document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
      ? setScroll(true)
      : setScroll(false);

  window.addEventListener("scroll", changeScroll);


  return (
    <>
      <Flex
        bg={useColorModeValue("gray.100", "gray.900")}
        px={4}
        h={16}
        boxShadow={scroll ? "base" : "none"}
        zIndex="sticky"
        position="fixed"
        as="header"
        alignItems={"center"}
        justifyContent={"space-between"}
        w="100%"
      >
        <Link onClick={() => {
          const section = document.querySelector('#hero');
          section.scrollIntoView({ behavior: "smooth" });
        }}>
            <Image src={logo} style={{height: 40, marginLeft: 10}} color={color} />
        </Link>

        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            {isLargerThanMD ? (
              <>
                <Button name='about' variant="ghost" onClick={scrollToSection}>
                  About
                </Button>
                <Button name='experience' variant="ghost" onClick={scrollToSection}>
                  Experience
                </Button>
                <Button name='projects' variant="ghost" onClick={scrollToSection}>
                  Projects
                </Button>
                <Button name='contact' variant="ghost" onClick={scrollToSection}>
                  Contact
                </Button>
              </>
            ) : (
              <></>
            )}

            {isLargerThanMD ? (
              <></>
            ) : (
              <>

                <Button
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  onClick={onOpen}
                ></Button>
                <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerBody>
                      <Button name='about' variant="ghost" onClick={scrollToSection}>
                        About
                      </Button>
                      <Button name='experience' variant="ghost" onClick={scrollToSection}>
                        Experience
                      </Button>
                      <Button name='projects' variant="ghost" onClick={scrollToSection}>
                        Projects
                      </Button>
                      <Button name='contact' variant="ghost" onClick={scrollToSection}>
                        Contact
                      </Button>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}
