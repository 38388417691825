import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Heading
} from "@chakra-ui/react";
import { Fade } from "react-reveal";

export default function About({ color }) {
    return (
      <>
        <Heading>
          <link
            href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
            rel="stylesheet"
          />
        </Heading>
        <Container maxW={"3xl"} id="about">
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 14 }}
            pb={{ base: 20, md: 36 }}
          >
            <Stack align="center" direction="row" px={4}>
              <HStack mx={4}>
                <Text color={`${color}.400`} fontWeight={800}>
                  01
                </Text>
                <Text fontWeight={800}>About</Text>
              </HStack>
              <Divider orientation="horizontal" />
            </Stack>
            <Text color={"gray.200"} fontSize={"xl"} px={4}>
              <Fade bottom>
                I’m a dedicated software developer passionate about continuous learning. 
                With a solid foundation in coding and problem-solving, I approach challenges with enthusiasm and a
                willingness to grow, aiming to contribute to impactful projects.
              </Fade>
            </Text>
          </Stack>
        </Container>
      </>
    );
}
