import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Button,
  Card,
  CardBody,
  Image,
  Heading,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";
import shopster from "../shopster.PNG";
import easychat from "../chat-app.png";
import hbnb from "../hbnb.PNG";
import simple_shell from "../simple_shell.PNG";
import { Fade } from "react-reveal";

export default function Projects({ color }) {
  return (
    <>
      <Container maxW={"3xl"} id="projects">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                03
              </Text>
              <Text fontWeight={800}>Projects</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Stack px={4} spacing={4}>
            <Fade bottom>
              <Card
                key="shopster"
                direction={{
                  base: "column",
                }}
                overflow="hidden"
              >
                <Image src={shopster} objectFit="cover" />

                <Stack>
                  <CardBody align="left">
                    <Heading size="md">Shopster</Heading>

                    <Text py={2}>
                      This full-stack application integrates a React front-end
                      with a Node.js/Express back-end to provide user
                      authentication, product management, and payment handling.
                      Redux is used for managing global state across components,
                      ensuring efficient data flow. MongoDB serves as the
                      database to manage products, orders, and wishlists.
                    </Text>

                    <HStack py={2}>
                      <a key="link" href="https://github.com/xhuve/shopster">
                        <Button color={`${color}.400`}>Github</Button>
                      </a>
                    </HStack>
                  </CardBody>
                </Stack>
              </Card>
              <Card
                key="easychat"
                direction={{
                  base: "column",
                }}
                overflow="hidden"
              >
                <Image src={easychat} objectFit="cover" />

                <Stack>
                  <CardBody align="left">
                    <Heading size="md">EasyChat</Heading>

                    <Text py={2}>
                      This full-stack application features a React front-end
                      built with Vite and an Express back-end, supporting secure
                      authentication and real-time messaging. Tailwind CSS is
                      used for responsive UI styling, ensuring a sleek and
                      modern interface. The app provides a seamless
                      communication experience with fast, secure interactions.
                    </Text>

                    <HStack py={2}>
                      <a
                        key="link"
                        href="https://github.com/xhuve/chat-app-clone-V2"
                      >
                        <Button color={`${color}.400`}>Github</Button>
                      </a>
                    </HStack>
                  </CardBody>
                </Stack>
              </Card>
              <Card
                key="easychat"
                direction={{
                  base: "column",
                }}
                overflow="hidden"
              >
                <Image src={hbnb} objectFit="cover" />

                <Stack>
                  <CardBody align="left">
                    <Heading size="md">Holberton AirBnB</Heading>

                    <Text py={2}>
                      This dynamic web app is built with Flask, HTML, CSS, and
                      JavaScript, featuring a MySQL database for data
                      management. The architecture follows a repository pattern
                      for data persistence and modularity, while a facade
                      pattern coordinates API routes, models, and storage.
                      RESTful API routes in Flask ensure clean separation of
                      concerns for efficient application logic.
                    </Text>

                    <HStack py={2}>
                      <a
                        key="link"
                        href="https://github.com/xhuve/holbertonschool-hbnb"
                      >
                        <Button color={`${color}.400`}>Github</Button>
                      </a>
                    </HStack>
                  </CardBody>
                </Stack>
              </Card>
              <Card
                key="easychat"
                direction={{
                  base: "column",
                }}
                overflow="hidden"
              >
                <Image src={simple_shell} objectFit="cover" />

                <Stack>
                  <CardBody align="left">
                    <Heading size="md">Simple Shell</Heading>

                    <Text py={2}>
                      This Unix shell, written in C, allows users to run
                      commands either directly or from files. It handles Linux
                      processes efficiently and works in both interactive mode
                      (where users enter commands one at a time) and
                      non-interactive mode (running commands from a script). The
                      shell is simple to use, offering a basic interface for
                      executing common Unix commands.
                    </Text>

                    <HStack py={2}>
                      <a
                        key="link"
                        href="https://github.com/xhuve/holbertonschool-simple_shell"
                      >
                        <Button color={`${color}.400`}>Github</Button>
                      </a>
                    </HStack>
                  </CardBody>
                </Stack>
              </Card>
            </Fade>
          </Stack>
          {/* <Text color={"gray.600"} fontSize={"xl"} px={4}>
            Other Projects
          </Text>
          <SimpleGrid columns={[1, 2, 3]} px={4} spacing={4}>
                <Fade bottom>
                  <Card>
                    <Stack>
                      <CardBody align="left" h={[null, "40vh"]}>
                        <Heading size="sm">Project name</Heading>

                        <Text fontSize="sm" py={2}>
                          Project Desc
                        </Text>
                        <HStack spacing={2}>
                            <Link href='example.com' color={`${color}.400`}>
                              Link
                            </Link>
                        </HStack>
                      </CardBody>
                    </Stack>
                  </Card>
                </Fade>
          </SimpleGrid> */}
        </Stack>
      </Container>
    </>
  );
}
